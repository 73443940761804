// extracted by mini-css-extract-plugin
export var sectionClass = "VolunteersForm-module--sectionClass--fFPqN";
export var successSectionClass = "VolunteersForm-module--successSectionClass--pwcJx";
export var rowClass = "VolunteersForm-module--rowClass--gRipv";
export var formControlClass = "VolunteersForm-module--formControlClass--RcZIM";
export var formControlTextAreaClass = "VolunteersForm-module--formControlTextAreaClass--Kr2bl";
export var leafletQuantityClass = "VolunteersForm-module--leafletQuantityClass--0KGaW";
export var checkboxGroupClass = "VolunteersForm-module--checkboxGroupClass--dfRul";
export var checkboxContainerClass = "VolunteersForm-module--checkboxContainerClass--Oa40y";
export var checkmarkClass = "VolunteersForm-module--checkmarkClass--yJR52";
export var sendBtnClass = "VolunteersForm-module--sendBtnClass--qydAn";
export var successContainerClass = "VolunteersForm-module--successContainerClass--aI1sH";
export var successMsgClass = "VolunteersForm-module--successMsgClass---N7y7";
export var activityCheckBoxClass = "VolunteersForm-module--activityCheckBoxClass--BtAhQ";