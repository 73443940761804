import React, { useState } from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap"
import axios from "axios"
import queryString from "query-string"

import Link from "../UI/Link"
import {
  sectionClass,
  successSectionClass,
  rowClass,
  formControlClass,
  successContainerClass,
  successMsgClass,
  sendBtnClass,
  checkboxGroupClass,
  checkboxContainerClass,
  checkmarkClass,
  formControlTextAreaClass,
  activityCheckBoxClass
} from "./VolunteersForm.module.scss"

export const fragment = graphql`
  fragment volunteersFormFragment on WpPage_Flexlayouts_FlexibleLayouts_VolunteersForm {
    instructionsAboutYourself
    instructionsActivities
    instructionsReferees
    volunteeringActivities {
      activity
    }
    privacyLink {
      ... on WpPage {
        link
        uri
      }
    }
    submitMessage
    emailTo
  }
`

const VolunteersForm = ({
  instructionsAboutYourself,
  instructionsActivities,
  instructionsReferees,
  volunteeringActivities,
  privacyLink,
  submitMessage,
  emailTo
}) => {

  const [status, setStatus] = useState({ initial: true })
  const [submitDisabled, setSubmitDisabled] = useState(true)

  const validate = event => {
    let inputs = document.getElementsByName("form-volunteers-activities");
    let activities = [];
    inputs.forEach(input => {
      if(input.checked === true){
        activities.push(input.value);
      }
    })
    const form = event.currentTarget

    if (form.checkValidity() === true && activities.length>0) {
        setSubmitDisabled(false)
    } else {
        setSubmitDisabled(true)
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    const form = event.currentTarget

    let inputs = document.getElementsByName("form-volunteers-activities");
    let activities = "";
    inputs.forEach(input => {
      if(input.checked === true){
        activities+=input.value + ', ';
      }
    })

    const data = {
      firstName: form.elements.firstName.value,
      lastName: form.elements.lastName.value,
      addressLine1: form.elements.addressLine1.value,
      addressLine2: form.elements.addressLine2.value,
      city: form.elements.city.value,
      state: form.elements.state.value,
      postcode: form.elements.postcode.value,
      country: form.elements.country.value,
      email: form.elements.email.value,
      mobilephone: form.elements.mobilephone.value,
      activities: activities,
      about: form.elements.about.value,
      referees: form.elements.referees.value,
      emailTo: emailTo
    }

    console.log(data);

    const postForm = async () => {
      try {
        const response = await axios.post(
          `${process.env.VOLUNTEERS_FORM}`,
          queryString.stringify(data)
        )
        setStatus({
          success: submitMessage,
          initial: false
        })
      } catch (exception) {
        setStatus({
          ...status,
          exception: `${exception.response.status}: ${exception.response.statusText}`
        })
      }
    }
    postForm()
  }

  return (
    <section
      className={status && status.success ? successSectionClass : sectionClass}>
      {status && status.initial && (
        <Container className="py-5 bg-purple">
          <Row className={rowClass}>
            <Col md={10} className="mx-auto">
              <Form
                id="LeafletRequestForm"
                noValidate
                onChange={validate}
                onSubmit={handleSubmit}
                method="post">
                <>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="firstName">
                        <Form.Label for="firstName">First name*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="firstName"
                          type="text"
                          required
                          maxLength={50}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="lastName">
                        <Form.Label for="lastName">Last name*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="lastName"
                          type="text"
                          required
                          maxLength={50}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="addressLine1">
                        <Form.Label for="addressLine1">Address Line 1*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="addressLine1"
                          type="text"
                          required
                          maxLength={100}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="addressLine2">
                        <Form.Label for="addressLine2">Address Line 2</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="addressLine2"
                          type="text"
                          maxLength={100}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="city">
                        <Form.Label for="city">
                          City*
                        </Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="city"
                          type="text"
                          required
                          maxLength={255}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="state">
                        <Form.Label for="state">
                          County / State / Region*
                        </Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="state"
                          type="text"
                          required
                          maxLength={255}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="postcode">
                        <Form.Label for="postcode">ZIP / Postal Code*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="postcode"
                          type="text"
                          required
                          maxLength={20}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="country">
                        <Form.Label for="country">Country*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="country"
                          type="text"
                          required
                          maxLength={50}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="email">
                        <Form.Label for="email">Email*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="email"
                          type="email"
                          required
                          maxLength={255}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="mobilephone">
                        <Form.Label for="mobilephone">Mobile phone*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="mobilephone"
                          type="tel"
                          maxLength="11"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="my-mid4">
                    <Col className="mb-3">
                      <p>
                        {instructionsActivities}
                      </p>
                    </Col>
                    <Col>
                    {volunteeringActivities.map((item, index) => (
                      <Form.Check
                        key={index}
                        inline
                        label={item.activity}
                        name="form-volunteers-activities"
                        type="checkbox"
                        className={activityCheckBoxClass}
                        value={item.activity}
                      />
                      ))}
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="about">
                        <Form.Label for="about">{instructionsAboutYourself}</Form.Label>
                        <Form.Control
                          className={formControlTextAreaClass}
                          name="about"
                          as="textarea"
                          rows={3}
                          required
                          maxLength={500}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="referees">
                        <Form.Label for="referees">{instructionsReferees}</Form.Label>
                        <Form.Control
                          className={formControlTextAreaClass}
                          name="referees"
                          as="textarea"
                          rows={3}
                          required
                          maxLength={500}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="d-flex justify-content-between align-items-center">
                    <Col md={6} lg={7}>
                      <div
                        className={`${checkboxGroupClass} form-group form-check`}>
                        <label
                          className={`${checkboxContainerClass} form-check-label text-start`}
                          htmlFor="terms">
                          <input
                            type="checkbox"
                            className={`form-check-input`}
                            id="terms"
                            name="terms"
                            required
                          />
                          <span className={checkmarkClass}/>I agree to the
                          EPT{" "}
                          <Link
                            classes="linkClass"
                            link={privacyLink?.uri}
                            title="Privacy Policy"
                          />
                          {/*{" "} and{" "}
                          <Link
                            classes="linkClass"
                            link={conditionsLink?.link}
                            title="Terms"
                          /> */}
                          {"."}
                        </label>
                      </div>
                    </Col>
                    <Col md={6} lg={5}>
                      <Form.Group className="text-sm-end">
                        <Button
                          type="submit"
                          className={sendBtnClass}
                          disabled={submitDisabled}>
                          Submit
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
      {status && status.success && (
        <Container className={successContainerClass}>
          <div className={successMsgClass}>{status.success}</div>
        </Container>
      )}
      {status && status.exception && (
        <Alert variant="danger" className="my-5">
          <Alert.Heading>{status.exception}</Alert.Heading>
        </Alert>
      )}
    </section>
  )
}

export default VolunteersForm